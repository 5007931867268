import WrapJson from './components/Wrap.json'
import TypographyJson from './components/Typography.json'
import TransitionJson from './components/Transition.json'
import TooltipJson from './components/Tooltip.json'
import ToastJson from './components/Toast.json'
import TextareaJson from './components/Textarea.json'
import TagJson from './components/Tag.json'
import TabsJson from './components/Tabs.json'
import TableJson from './components/Table.json'
import SwitchJson from './components/Switch.json'
import StepperJson from './components/Stepper.json'
import StatJson from './components/Stat.json'
import StackJson from './components/Stack.json'
import SpinnerJson from './components/Spinner.json'
import SpacerJson from './components/Spacer.json'
import SliderJson from './components/Slider.json'
import SkipNavJson from './components/SkipNav.json'
import SkeletonJson from './components/Skeleton.json'
import SelectJson from './components/Select.json'
import RadioJson from './components/Radio.json'
import ProviderJson from './components/Provider.json'
import ProgressJson from './components/Progress.json'
import PortalJson from './components/Portal.json'
import PopperJson from './components/Popper.json'
import PopoverJson from './components/Popover.json'
import PinInputJson from './components/PinInput.json'
import NumberInputJson from './components/NumberInput.json'
import ModalJson from './components/Modal.json'
import MenuJson from './components/Menu.json'
import MediaQueryJson from './components/MediaQuery.json'
import ListJson from './components/List.json'
import LinkJson from './components/Link.json'
import KbdJson from './components/Kbd.json'
import InputJson from './components/Input.json'
import IndicatorJson from './components/Indicator.json'
import ImageJson from './components/Image.json'
import IconJson from './components/Icon.json'
import HighlightJson from './components/Highlight.json'
import GridJson from './components/Grid.json'
import FormControlJson from './components/FormControl.json'
import FocusLockJson from './components/FocusLock.json'
import FlexJson from './components/Flex.json'
import EnvJson from './components/Env.json'
import EditableJson from './components/Editable.json'
import DividerJson from './components/Divider.json'
import CssResetJson from './components/CssReset.json'
import ControlBoxJson from './components/ControlBox.json'
import ContainerJson from './components/Container.json'
import ColorModeJson from './components/ColorMode.json'
import CodeJson from './components/Code.json'
import CloseButtonJson from './components/CloseButton.json'
import ClickableJson from './components/Clickable.json'
import CheckboxJson from './components/Checkbox.json'
import CenterJson from './components/Center.json'
import CardJson from './components/Card.json'
import ButtonJson from './components/Button.json'
import BreadcrumbJson from './components/Breadcrumb.json'
import BoxJson from './components/Box.json'
import BadgeJson from './components/Badge.json'
import AvatarJson from './components/Avatar.json'
import AspectRatioJson from './components/AspectRatio.json'
import AlertJson from './components/Alert.json'
import AccordionJson from './components/Accordion.json'

export const Wrap = WrapJson
export const Typography = TypographyJson
export const Transition = TransitionJson
export const Tooltip = TooltipJson
export const Toast = ToastJson
export const Textarea = TextareaJson
export const Tag = TagJson
export const Tabs = TabsJson
export const Table = TableJson
export const Switch = SwitchJson
export const Stepper = StepperJson
export const Stat = StatJson
export const Stack = StackJson
export const Spinner = SpinnerJson
export const Spacer = SpacerJson
export const Slider = SliderJson
export const SkipNav = SkipNavJson
export const Skeleton = SkeletonJson
export const Select = SelectJson
export const Radio = RadioJson
export const Provider = ProviderJson
export const Progress = ProgressJson
export const Portal = PortalJson
export const Popper = PopperJson
export const Popover = PopoverJson
export const PinInput = PinInputJson
export const NumberInput = NumberInputJson
export const Modal = ModalJson
export const Menu = MenuJson
export const MediaQuery = MediaQueryJson
export const List = ListJson
export const Link = LinkJson
export const Kbd = KbdJson
export const Input = InputJson
export const Indicator = IndicatorJson
export const Image = ImageJson
export const Icon = IconJson
export const Highlight = HighlightJson
export const Grid = GridJson
export const FormControl = FormControlJson
export const FocusLock = FocusLockJson
export const Flex = FlexJson
export const Env = EnvJson
export const Editable = EditableJson
export const Divider = DividerJson
export const CssReset = CssResetJson
export const ControlBox = ControlBoxJson
export const Container = ContainerJson
export const ColorMode = ColorModeJson
export const Code = CodeJson
export const CloseButton = CloseButtonJson
export const Clickable = ClickableJson
export const Checkbox = CheckboxJson
export const Center = CenterJson
export const Card = CardJson
export const Button = ButtonJson
export const Breadcrumb = BreadcrumbJson
export const Box = BoxJson
export const Badge = BadgeJson
export const Avatar = AvatarJson
export const AspectRatio = AspectRatioJson
export const Alert = AlertJson
export const Accordion = AccordionJson

const json = {
Wrap,
Typography,
Transition,
Tooltip,
Toast,
Textarea,
Tag,
Tabs,
Table,
Switch,
Stepper,
Stat,
Stack,
Spinner,
Spacer,
Slider,
SkipNav,
Skeleton,
Select,
Radio,
Provider,
Progress,
Portal,
Popper,
Popover,
PinInput,
NumberInput,
Modal,
Menu,
MediaQuery,
List,
Link,
Kbd,
Input,
Indicator,
Image,
Icon,
Highlight,
Grid,
FormControl,
FocusLock,
Flex,
Env,
Editable,
Divider,
CssReset,
ControlBox,
Container,
ColorMode,
Code,
CloseButton,
Clickable,
Checkbox,
Center,
Card,
Button,
Breadcrumb,
Box,
Badge,
Avatar,
AspectRatio,
Alert,
Accordion
}

export const allPropDocs = Object.fromEntries(
  Object.values(json).flatMap((doc) => Object.entries(doc)),
)

export const getPropDoc = (name) => allPropDocs[name]